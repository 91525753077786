<template>
<v-layout wrap>
    <v-flex mx-10 pb-12 v-if="type=='SupplyWorkflow' && workflowId==3 " text-center xs12 sm12 :class="$vuetify.breakpoint.xsOnly?'mt-10':''">
        <v-img :width="$vuetify.breakpoint.xsOnly?350:400" :height="$vuetify.breakpoint.xsOnly?100:150" :src="require('@/assets/supply_workflow_1.png')"></v-img>
        <label>Supply Workflow 1</label>
    </v-flex>
    <v-flex mx-10 pb-12 v-else-if="workflowId==1" text-center xs12 sm12>
        <v-img :width="$vuetify.breakpoint.xsOnly?450:600" :height="$vuetify.breakpoint.xsOnly?130:230" :src="require('@/assets/workflow-1.png')"></v-img>
        <label>Workflow 1</label>
    </v-flex>
    <v-flex mx-10 pb-12 v-else-if="workflowId==2" text-center xs12 sm12 :class="$vuetify.breakpoint.xsOnly?'mt-10':''">
        <v-img :width="$vuetify.breakpoint.xsOnly?300:480" :height="$vuetify.breakpoint.xsOnly?140:230" :src="require('@/assets/workflow-2.png')"></v-img>
        <label>Workflow 2</label>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    props: {
        workflowId: {
            type: String,
            default:'1' 
        },
        type:{
            type:String
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
