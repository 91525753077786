<template>
    <div class="format">
        <v-card class="mx-auto" elevation="10" max-width="700">
            <v-carousel v-if="vendor.bannerImages && vendor.bannerImages.length > 0" cycle hide-delimiter-background
                show-arrows-on-hover height="400px">
                <v-carousel-item v-for="(image, i) in vendor.bannerImages" :key="i"
                    :src="host + image.path"></v-carousel-item>
            </v-carousel>
            <v-carousel v-else cycle hide-delimiter-background show-arrows-on-hover>
                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                    <v-sheet :color="colors[i]" height="100%">
                        <v-row class="fill-height" align="center" justify="center">
                            <div class="display-3">{{ slide }} Slide</div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
            <v-card-text class="pt-6 icon-position">
                <v-btn @click="$router.push('/app/settings/' + $store.getters.vendor._id)" absolute class="mr-2"
                    :style="theme" fab large right top>
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <row-item label="Logo :"></row-item>
                <v-img v-if="vendor.logo" :src="host + vendor.logo.path" height="64px" width="120px"></v-img>
                <v-icon v-else x-large class="pl-4" :style="themeInverted">mdi-handshake</v-icon>
                <row-item label="Business Name :" :value="vendor.businessName"></row-item>
                <row-item label="Business Email :" :value="vendor.email"></row-item>
                <row-item label="Contact no :" :value="vendor.mobileNo"></row-item>
                <row-item v-if="vendor.description" label="Store Description :" :value="vendor.description"></row-item>
                <row-item v-if="vendor.whatsappNo" label="Whatsapp no :" :value="vendor.whatsappNo"></row-item>
                <row-item v-if="vendor.whatsappMsg" label="Whatsapp Catalogue Message :"
                    :value="vendor.whatsappMsg"></row-item>
                <row-item label="Address :" :value="vendor.address"></row-item>
                <row-item label="Tax (%) :" :value="vendor.taxPercent"></row-item>
                <row-item label="Scan QR for Product Catalogue"></row-item>
                <v-layout>
                    <v-flex text-center>
                        <qrcode id="qrcode" ref="qrcode" class="pa-4" :value="vendor.url" size="200" level="H"></qrcode>
                        <a @click="download('qrcode', 'QrCode.png')">Download QR Code</a>
                    </v-flex>
                </v-layout>
                <row-item label="Store UI" :value="vendor.storeUI"></row-item>
                <row-item if="vendor.storeUI=='Webstore 1.0'" label="Product in Single Row in Mobile"
                    :value="`${vendor.imageInRow == 1 ? 'Single Card' : 'Two Card'}`"></row-item>
                <row-item else-if="vendor.storeUI=='Webstore 2.0'" label="Product Card Layout in Mobile"
                    :value="`${vendor.productCardMobile == 1 ? 'Horizontal Card' : 'Vertical Card'}`"></row-item>
                <row-item label="Show banner" :value="`${vendor.showBanner ? 'Enabled' : 'Disabled'}`"></row-item>
                <v-row>
                    <v-flex mx-3 sm3>
                        <row-item label="Theme Color"></row-item>
                    </v-flex>
                    <v-flex mt-2>
                        <v-icon :style="themeInverted" size="35">mdi-square</v-icon>
                    </v-flex>
                </v-row>
                <v-row>
                    <v-flex mx-3 sm3>
                        <row-item label="Font Color"></row-item>
                    </v-flex>
                    <v-flex mt-2 mx-1>
                        <v-icon :style="theme" size="28">mdi-format-size</v-icon>
                    </v-flex>
                </v-row>
                <v-layout wrap>
                    <v-flex sm12 pa-3 v-if="vendor.facebook"><label>
                            <v-icon>mdi-facebook</v-icon>{{ vendor.facebook }}
                        </label></v-flex>
                    <v-flex sm12 pa-3 v-if="vendor.twitter"><label>
                            <v-icon>mdi-twitter</v-icon>{{ vendor.twitter }}
                        </label></v-flex>
                    <v-flex sm12 pa-3 v-if="vendor.instagram"><label>
                            <v-icon>mdi-instagram</v-icon>{{ vendor.instagram }}
                        </label></v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card class="mx-auto my-6" elevation="10" max-width="700"
            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('order') > -1">
            <v-card-title :style="themeInverted">Delivery</v-card-title>
            <row-item v-if="vendor.delivery.length > 0" label="Delivery Options"></row-item>
            <span v-if="vendor.delivery.length > 0">
                <span class="left-padding font-format" v-for="(type) in vendor.delivery" :key="type.code">
                    <span v-if="type.code">
                        <v-icon class="pr-2">mdi-check-circle</v-icon>{{ type.label }}<br>
                    </span>
                    <span v-else>
                        <v-icon class="pr-2">mdi-check-circle</v-icon>{{ type }}<br>
                    </span>
                </span>
            </span>
            <row-item v-if="showPickup" label="Pickup Points"></row-item>
            <span v-if="showPickup">
                <span class="left-padding font-format" v-for="(item) in vendor.pickupPoints" :key="item.name">
                    <span><label class="subtitle-1">{{ item.name }}</label><br></span>
                    <span class="mx-4">
                        <v-icon small>mdi-map-marker</v-icon>{{ item.address }}<br><br>
                    </span>
                </span>
            </span>
            <row-item v-if="vendor.delivery.length == 0" label="Delivery Message"
                :value="vendor.deliveryMsg"></row-item>
            <row-item label="Delivery After" :value="vendor.deliveryDays + ' Day/s'"></row-item>
            <row-item v-if="vendor.deliveryCharge > 0" label="Delivery Charges(₹)"
                :value="vendor.deliveryCharge"></row-item>
            <row-item v-if="vendor.freeDeliveryAbove > 0" label="Free Delivery in orders above(₹)"
                :value="vendor.freeDeliveryAbove"></row-item>
            <row-item label="Negative Stock in Inventory"
                :value="`${vendor.negativeStock == true ? 'Allowed' : 'Not Allowed'}`"></row-item>
            <v-row>
                <v-flex mx-3>
                    <row-item label="Webstore Order flow"
                        :value="`${vendor.storeWorkflowId == 1 ? 'Workflow 1' : 'Workflow 2'}`"></row-item>
                </v-flex>
                <v-flex mx-3>
                    <row-item label="Update Inventory on Status" :value="vendor.storeStatus"></row-item>
                </v-flex>
            </v-row>
            <workflow-image :workflowId="vendor.storeWorkflowId"></workflow-image>
            <v-row>
                <v-flex mx-3>
                    <row-item label="Counter Order flow"
                        :value="`${vendor.counterWorkflowId == 1 ? 'Workflow 1' : 'Workflow 2'}`"></row-item>
                </v-flex>
                <v-flex mx-3>
                    <row-item label="Update Inventory on Status" :value="vendor.counterStatus"></row-item>
                </v-flex>
            </v-row>
            <workflow-image :workflowId="vendor.counterWorkflowId"></workflow-image>
            <row-item label="Order Challan :"
                :value="`${vendor.enableSaleChallan ? 'Enabled' : 'Disabled'}`"></row-item>
            <row-item label="Order Supply flow" value="Supply Workflow 1"></row-item>
            <workflow-image :workflowId="vendor.supplyWorkflowId" type="SupplyWorkflow"></workflow-image>
        </v-card>
        <v-card v-if="vendor.enableRewards" class="mx-auto my-6" elevation="10" max-width="700">
            <v-card-title :style="themeInverted">Rewards</v-card-title>
            <row-item v-if="vendor.rewardSetting.singlePointCost > 0" label="Single Reward Point Cost"
                :value="vendor.rewardSetting.singlePointCost"></row-item>
            <row-item v-if="vendor.rewardSetting.registrationPointCost > 0" label="Registration Points"
                :value="vendor.rewardSetting.registrationPointCost"></row-item>
            <row-item v-if="vendor.rewardSetting.orderPoint.pointType" label="Order Point Type"
                :value="vendor.rewardSetting.orderPoint.pointType"></row-item>
            <span v-if="vendor.rewardSetting.orderPoint.pointType == orderRewardType.SLAB">
                <row-item label="Slab Details : "></row-item>
                <v-simple-table class="pb-8 mx-12">
                    <thead>
                        <tr>
                            <th class="text-right">Slab No</th>
                            <th class="text-right">Min Order Total</th>
                            <th class="text-right">Max Order Total</th>
                            <th class="text-right">Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in vendor.rewardSetting.orderPoint.slabs" :key="index">
                            <td class="text-right">{{ index + 1 }}</td>
                            <td class="text-right">{{ item.minOrderTotal }}</td>
                            <td class="text-right">{{ item.maxOrderTotal == -1 ? 'No Limit' : item.maxOrderTotal }}</td>
                            <td class="text-right">{{ item.point }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </span>
            <row-item v-if="vendor.rewardSetting.orderPoint.pointType == orderRewardType.PERCENT" label="Percent"
                :value="vendor.rewardSetting.orderPoint.percent"></row-item>
            <row-item v-if="vendor.rewardSetting.orderPoint.pointType == orderRewardType.FLATAMOUNT" label="Flat Amount"
                :value="vendor.rewardSetting.orderPoint.flatAmount"></row-item>
            <row-item v-if="vendor.rewardSetting.redeemOn.point > 0" label="Redeem After collecting total points"
                :value="vendor.rewardSetting.redeemOn.point"></row-item>
            <row-item v-if="vendor.rewardSetting.redeemOn.maxPercent > 0" label="Redeem Point on order percent"
                :value="vendor.rewardSetting.redeemOn.maxPercent"></row-item>
            <row-item v-if="vendor.rewardSetting.redeemOn.maxValue > 0" label="Max points redeem on single order"
                :value="vendor.rewardSetting.redeemOn.maxValue"></row-item>
        </v-card>
        <v-card class="mx-auto my-6" elevation="10" max-width="700">
            <v-card-title :style="themeInverted">Notification</v-card-title>
            <v-simple-table class="pb-8 mx-12">
                <thead>
                    <tr>
                        <th style=font-size:16px>Notification</th>
                        <th style=font-size:14px>Emall</th>
                        <th style=font-size:14px>SMS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Notification for new order</td>
                        <td>
                            <v-icon v-if="vendor.emailNotification"
                                class="active-icon">mdi-check-circle-outline</v-icon>
                            <v-icon v-if="!vendor.emailNotification" class="deactive-icon">mdi-cancel</v-icon>
                        </td>
                        <td>
                            <v-icon v-if="vendor.smsNotification" class="active-icon">mdi-check-circle-outline</v-icon>
                            <v-icon v-if="!vendor.smsNotification" class="deactive-icon">mdi-cancel</v-icon>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
        <v-card class="mx-auto my-6" elevation="10" max-width="700"
            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('order') > -1">
            <v-card-title :style="themeInverted">Payment options on Checkout</v-card-title>
            <v-simple-table class="pb-8 mx-12">
                <thead>
                    <tr>
                        <th style=font-size:16px>Payment Options</th>
                        <th style=font-size:14px>Online Payment</th>
                        <th style=font-size:14px>Cash on Delivery</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Payment enabled for user</td>
                        <td>
                            <v-icon v-if="vendor.paymentOption.online"
                                class="active-icon">mdi-check-circle-outline</v-icon>
                            <v-icon v-if="!vendor.paymentOption.online" class="deactive-icon">mdi-cancel</v-icon>
                        </td>
                        <td>
                            <v-icon v-if="vendor.paymentOption.cash"
                                class="active-icon">mdi-check-circle-outline</v-icon>
                            <v-icon v-if="!vendor.paymentOption.cash" class="deactive-icon">mdi-cancel</v-icon>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import RowItem from '../../../components/RowItem.vue';
import WorkflowImage from '@/components/WorkflowImage'
export default {
    components: {
        RowItem,
        WorkflowImage
    },
    data() {
        return {
            colors: [
                'indigo',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
            ],
            vendor: {
                url: '',
                delivery: '',
                pickupPoints: [],
                rewardSetting: {
                    orderPoint: {},
                    redeemOn: {}
                },
                paymentOption: {}
            },
            showPickup: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.vendor = await this.getItem(appConstants.VENDORSETTING_API + "/" + this.$store.getters.vendor._id)
            this.showPickup = this.vendor.delivery.length > 0 && this.vendor.delivery[0].code == 'pick-up-from-shop' || this.vendor.delivery.length > 1 && this.vendor.delivery[1].code == 'pick-up-from-shop'
            this.vendor.url = this.generateUrl(this.vendor.slug)
        },
        download(id, fileName) {
            var canvasElement = this.$refs[id].$el.children[0];
            var MIME_TYPE = "image/png";
            var imgURL = canvasElement.toDataURL(MIME_TYPE);
            var dlLink = document.createElement("a");
            dlLink.download = fileName;
            dlLink.href = imgURL;
            dlLink.dataset.downloadurl = [
                MIME_TYPE,
                dlLink.download,
                dlLink.href
            ].join(":");
            document.body.appendChild(dlLink);
            dlLink.click();
            document.body.removeChild(dlLink);
        },
    },
}
</script>

<style scoped>
.format {
    padding-top: 5%;
}

.icon-position {
    position: relative;
}

.left-padding {
    padding-left: 2%;
}

.font-format {
    font-weight: 600;
}
</style>
